import FactCheckIcon from '@mui/icons-material/FactCheck';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ShieldIcon from '@mui/icons-material/Shield';
import SummarizeIcon from '@mui/icons-material/Summarize';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import { useMemo } from 'react';

import { useAuthContext } from 'src/auth/hooks';
import SvgColor from 'src/components/svg-color';
import { UserRoles } from 'src/constants';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  work: icon('ic_workFilled'),
  photo: icon('ic_photoLibraryFilled'),
  business: icon('ic_businessFilled'),
  settings: icon('ic_setting'),
  stains: icon('ic_textSnippetFilled'),
  templates: icon('ic_fliptoFrontFilled'),
  insurance: <ShieldIcon />,
  billing: icon('ic_billing'),
  billingSettings: icon('ic_payment'),
  caseList: <SummarizeIcon />,
  pendingList: <FactCheckIcon />,
  abnormalList: <MedicalInformationIcon />,
  labReports: <InsertDriveFileRoundedIcon sx={{ transform: 'rotateX(180deg)' }} />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { selectedUser } = useAuthContext();

  const data = useMemo(() => {
    const appTypes = selectedUser?.applications || ['IMS'];

    const pathologist = [UserRoles.PATHOLOGIST, UserRoles.TC_PATHOLOGIST, UserRoles.LAB_DIRECTOR];
    const lab_user = [UserRoles.LAB_USER, UserRoles.LAB_DIRECTOR, UserRoles.LAB_MANAGER];
    const billing_user = [UserRoles.BILLING_STAFF, UserRoles.BILLING_ADMINISTRATOR];
    return [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: 'Accession List',
            path: paths.dashboard.accessionList,
            icon: ICONS.work,
            roles: [
              UserRoles.LAB_ADMINISTRATOR,
              UserRoles.OFFICE_USER,
              UserRoles.PHYSICIAN,
              ...billing_user,
              ...lab_user,
              ...pathologist,
            ],
          },
          {
            title: 'Shared Accessions',
            path: paths.dashboard.sharedAccessions,
            icon: ICONS.work,
            roles: [...pathologist],
          },
          {
            title: 'Image List',
            path: paths.dashboard.imageList,
            icon: ICONS.photo,
            appType: 'IMS',
            roles: [UserRoles.LAB_ADMINISTRATOR, ...lab_user],
          },
          {
            title: 'Model Review',
            path: paths.dashboard.modelReview,
            icon: ICONS.photo,
            roles: [UserRoles.TECHNICIAN],
          },
          {
            title: 'Laboratory List',
            path: paths.dashboard.laboratoryList,
            icon: ICONS.work,
            roles: [UserRoles.SYSTEM_ADMINISTRATOR],
          },
          {
            title: 'Stain List',
            path: paths.dashboard.stainList,
            icon: ICONS.stains,
            appType: 'LIS',
            roles: [UserRoles.LAB_ADMINISTRATOR],
          },
          {
            title: 'Stain Requests',
            path: paths.dashboard.stainRequests,
            icon: ICONS.photo,
            appType: 'LIS',
            roles: [UserRoles.LAB_ADMINISTRATOR, ...lab_user],
          },
          {
            title: 'Templates',
            path: paths.dashboard.templateList,
            icon: ICONS.templates,
            appType: 'LIS',
            roles: [UserRoles.LAB_ADMINISTRATOR, ...pathologist],
          },
          {
            title: 'Interface Tracking',
            path: paths.dashboard.interfaceTracking,
            icon: ICONS.templates,
            appType: 'LIS',
            roles: [UserRoles.LAB_ADMINISTRATOR, ...lab_user],
          },
          {
            title: 'Billing',
            path: paths.dashboard.billing,
            icon: ICONS.billing,
            roles: [UserRoles.BILLING_STAFF, UserRoles.BILLING_ADMINISTRATOR],
          },
          {
            title: 'Billing Settings',
            path: paths.dashboard.billingSettings,
            icon: ICONS.billingSettings,
            roles: [UserRoles.BILLING_ADMINISTRATOR],
          },
          {
            title: 'Case List',
            path: paths.dashboard.caseList,
            icon: ICONS.caseList,
            roles: [UserRoles.CP_STAFF, UserRoles.CP_ADMINISTRATOR, UserRoles.CP_OFFICE_USER],
          },
          {
            title: 'Sample Pending List',
            path: paths.dashboard.pendingList,
            icon: ICONS.pendingList,
            roles: [UserRoles.CP_STAFF, UserRoles.CP_ADMINISTRATOR],
          },
          {
            title: 'Abnormal List',
            path: paths.dashboard.abnormalList,
            icon: ICONS.abnormalList,
            roles: [UserRoles.CP_STAFF, UserRoles.CP_ADMINISTRATOR],
          },
        ].filter((item) => !item.appType || appTypes?.includes(item.appType)),
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'Administration',
        items: [
          {
            title: 'Clients',
            path: paths.dashboard.administration.clients,
            icon: ICONS.business,
            roles: [UserRoles.CP_ADMINISTRATOR, UserRoles.LAB_ADMINISTRATOR],
          },
          {
            title: 'Insurance',
            path: paths.dashboard.administration.insurance,
            icon: ICONS.insurance,
            roles: [UserRoles.CP_ADMINISTRATOR, UserRoles.LAB_ADMINISTRATOR],
          },
          {
            title: 'Lab Reports',
            path: paths.dashboard.administration.labReports,
            icon: ICONS.labReports,
            roles: [UserRoles.CP_ADMINISTRATOR, UserRoles.LAB_ADMINISTRATOR],
          },
        ],
      },
    ];
  }, [selectedUser?.applications]);

  return data;
}
